
.head-top-bar{
    padding: 15px 0 15px 0;
    height: 80px;
}

.footer-bar{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 25px 0 30px 0;
    margin-top: 50px;
}

.headline-container-short{
    padding: 8px 0 7px 0;
    margin-bottom: 10px;
}

.property-headline-container{
    margin-bottom: 14px;
}

.property-info-padding{
    padding: 0 0 25px 0;
}

.property-well {
    border: none;
    background-color: #e6e6e6;

    min-height: 20px;
    padding: 20px 0 50px;
    margin-bottom: 20px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.property-table td, .property-table th {
    width: 50%;
    border: none;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #e6e6e6;
}

.contact{
    position: relative;
    padding-left: 229px;
}

.team-logo{
    padding-bottom: 30px;
}

.nav-logo{
    background-color: #ffffff;
}

.request-list{
    padding-left: 10px;
}

.me-nav > li > a:hover,
.me-nav > li > a:focus {
    color: #f4934c;
    background-color: #ffffff;
}

.single-map{
    padding-bottom: 25px;
}

.welcome-actions{
    padding-top: 15px;
}

.nav-active {
    color: #ffffff;
}

.h4tp{
    padding-top: 25px;
}

.img-index{
    width: 25px;
}

.img-delete{
    position: absolute;
    top: -6px;
    right: -6px;
}

.img-rotate-left{
    position: absolute;
    top: -6px;
    left: 6px;
}

.img-rotate-right{
    position: absolute;
    top: -6px;
    left: 30px;
}

.img-container{
    margin:5px;
    width: 170px;
    height: 200px;
    padding: 20px 10px 0px 10px;
    text-align: center;
    border: 1px solid #000000;
    border-radius: 4px;
}

.img-single{
    max-width: 140px;
    max-height: 110px;
    margin: auto
}

.delete-btn{
    padding: 10px;
}

.the-padding{
    margin-left: 15px;
}

.the-shadow{
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-shadow: 0px 0px 35px 2px rgb(219, 217, 219);
    -moz-box-shadow: 0px 0px 35px 2px rgb(219, 217, 219);
    box-shadow: 0px 0px 35px 2px rgb(219, 217, 219);
}

.the-shadow:hover{
    -webkit-box-shadow: 0px 0px 35px 2px rgba(184,182,184,1);
    -moz-box-shadow: 0px 0px 35px 2px rgba(184,182,184,1);
    box-shadow: 0px 0px 35px 2px rgba(184,182,184,1);
}

.filter{
    background-color: #e7e7e7;
    margin-bottom: 25px;
    padding:15px;
    margin-right: 15px;
}

@media print{
    .print-hide{
        display:none;
    }
}

.np{
    padding: 0;
}

.tp{
    padding-top: 15px;
}

.pr{
    padding-right: 0;
}

.pb{
    padding-bottom: 15px;
}

.npb{
    padding-bottom: 0;
}

.lp{
    padding-left: 35px;
}

.pl{
    padding-left: 0;
}


.nm{
    margin: 0;
}

.mb{
    margin-bottom: 0;
}

.mt{
    margin-top: 30px;
}