/* -----------------------------------------------------------------------------
	Moje. - Responsive Bootstrap vCard HTML/CSS Template
	v.1.1
------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------- */
/* --- DOCUMENT SETUP
/* ----------------------------------------------------------------------------- */

	html {
		overflow-x: hidden;
	}

    body {
		color: #282828;
		overflow-x: hidden; 
		font-size: 12px;
		font-family: "Helvetica Neue",'Raleway',Helvetica,Arial,sans-serif;
	}
	
	.clear { display:block;visibility:hidden;clear:both;overflow:hidden;width:0;height:0; }
	
	a, a > * {
		color: inherit;
		transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-webkit-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
	}
	
	a:hover, a > *:hover, a:focus, a > *:focus {
		text-decoration: none;
	}
	
	.nav a {
		color: inherit;
	}
	
	p {
		line-height: 22px;
		margin-bottom: 5px;
	}

	strong {
		font-weight: none;
	}

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    object, video {
        display: block;
        height: auto;
    }
	
	textarea:focus, input:focus, a:focus, a:visited, *:focus {
		outline: none;
	}
	
	/* Headings
	 ------------------------------------------- */
	
	h1,h2,h3,h4,h5,h6 {
		font-family: inherit;
		margin-top: 0px;
	}
	
	h1,h2,h3 {
		font-weight: 100;
	}
	
	h4,h5,h6 {
		font-weight: 500;
	}
	
	h6 {
		text-transform: uppercase;
	}
	
	h1 { font-size: 70px; }
	h2 { font-size: 55px; }
	h3 { font-size: 45px; }
	h4 { font-size: 28px; }
	h5 { font-size: 16px; }
	h6 { font-size: 11px; }
	
	h1 { margin-bottom: 32px; }
	h2 { margin-bottom: 30px; }
	h3 { margin-bottom: 24px; }
	h4 { margin-bottom: 20px; }
	h5 { margin-bottom: 18px; }
	h6 { margin-bottom: 11px; }
	
	
/* ----------------------------------------------------------------------------- */
/* --- BASIC 
/* ----------------------------------------------------------------------------- */

	#page-content {
		position: relative;
		padding-left: 229px;
		height: 100%;
	}
	
	#page-content section {
		overflow: hidden;
	}
	
	.page-title { position: relative; overflow: hidden; z-index: 1; }
	.page-title .page-title-bg { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; }
	.page-title .page-title-bg img { position: absolute; top: 0; left: 0; }
	.page-title .content { position: relative; z-index: 2; }
	.page-title.bottom-padded { padding-bottom: 60px; }
	
	.container {
		position: relative;
		margin: -60px 60px 0px 60px;
		padding: 40px;
		background: #fff;
		z-index: 2;
	}

	.black { background-color: #000; }
	.dark { color: #fff; }
	.padded { padding: 60px; }
	.padded-short { padding: 30px; }
	.padded-vertical { padding: 50px 0px; }
	.short { margin-bottom: 0px !important; }
	.medium { margin-bottom: 15px !important; }
	.long { margin-bottom: 50px !important; }
	
	hr.sep { display: block; border: none; margin: 0; height: 50px; }
	hr.sep-line { display: block; border: none; margin:50px 0px; height: 1px; background-color: #eaeaea; }
	
	.top-bar, .bottom-bar, .end-of-section { font-size: 11px; font-weight: 500; }
	
	.end-of-section { padding-top: 50px; }
	.top-bar { padding-bottom: 70px; margin-bottom: 50px; border-bottom: 1px solid rgba(0,0,0,0.1); }
	.dark .top-bar { border-bottom: 1px solid rgba(255,255,255,0.2); }
	.bottom-bar { padding-top: 55px; margin-top: 50px; border-top: 1px solid rgba(0,0,0,0.1); }
	.dark  .bottom-bar { border-top: 1px solid rgba(255,255,255,0.2); }
	
	.title-icon, .title-icon:before { font-size: 75px !important; margin-left: 0px !important; margin-bottom: 20px; }
	.inline-icon, .inline-icon:before { font-size: 15px; }
	
	.icon-before { padding-right: 10px; }
	.icon-after { padding-left: 10px; }
	.icon-before-and-after { padding-right: 8px; padding-left: 8px; }
	
	.text-blue { color: #25b5f1; }
	.text-green { color: #add71c; }
	.text-red { color: #f65757; }
	.text-yellow { color: #fbce43; }
	.text-orange { color: #f4934c; }
	.text-violet { color: #816ae6; }
	.dark .text-muted { color: rgba(255,255,255,0.5); }
	

/* ----------------------------------------------------------------------------- */
/* --- NAVIGATION
/* ----------------------------------------------------------------------------- */

	header {
		position: fixed;
		width:230px;
		height: 100%;
		display: block;
		float: left;
		z-index: 1000;
		text-align: right;
		text-transform: uppercase;
	}
	
	.nav-white {
		background: #fff;
	}
	
	.nav-black {
		background: #000;
		color: #fff;
	}
	
	.nav-image {
		background-image: url('../images/backgrounds/nav-bg.jpg');
	}
	
	/* Logo
	------------------------------------------- */
	
	.me-logo {
		position: relative;
		height: 230px;
	}
	
	.me-logo {
		height:230px;
		color: #fff;
		text-align: right;
		text-transform: none;
	}
	
	.logo-content {
		position: absolute;
		top: 50%;
		right: 0;
		padding: 0px 15px;
	}
	
	.me-logo a:hover,
	.me-logo a:hover *,
	.me-logo a:focus,
	.me-logo a:focus * {
		color: inherit;
	}
	
	.me-logo h4 {
		font-weight: 100;
	}
	
	.logo-caption {
		font-size: 11px;
		display: block;
		color: #282828;
		font-weight: 500;
	}
	
	.me-logo a:hover .logo-caption,
	.me-logo a:focus .logo-caption {
		color: #282828;
	}
	
	/* Main Menu
	------------------------------------------- */
	
	.me-nav a:hover,
	.me-nav a > *:hover {
		color: inherit;
	}
	
	.me-nav {
		font-size: 11px;
		font-weight: 600;
	}
	
	.nav-white .me-nav > li {
		border-bottom: 1px solid #eaeaea;
	}
	
	.nav-black .me-nav > li {
		border-bottom: 1px solid #1d1d1d;
	}
	
	.me-nav > li:last-child {
		border-bottom: none;
	}
	
	.me-nav > li > a {
		padding: 18px 18px;
	}
	
	.me-nav > li > a:after {
		position: absolute;
		top: 0;
		right: -3px;
		height: 100%;
		width: 3px;
		content: " ";
		opacity: 0;
		visibility: hidden;
		transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-webkit-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
	}
	
	.me-nav > li.active > a:after {
		opacity: 1;
		visibility: visible;
	}
	
	.nav-white .me-nav > li > a:hover,
	.nav-white .me-nav > li > a:focus {
		background: none;
	}
	
	.nav-black .me-nav > li > a:hover,
	.nav-black .me-nav > li > a:focus {
		background: none;
	}
	
	.me-nav > li > a > i {
		margin-left: 20px;
		font-size: 13px;
	}
	
	.nav-white .me-nav > li > a > i {
		color: #d5d5d5;
	}
	
	.nav-black .me-nav > li > a > i {
		color: #4f4f4f;
	}
	
	/* Copyright
	------------------------------------------- */
		
	.copyright {
		position: absolute;
		bottom: 0;
		right: 0;
		padding-bottom: 30px;
		padding-right: 15px;
		font-size: 10px;
	}
	
	.copyright:before {
		position: absolute;
		top: -30px;
		right: 15px;
		height: 2px;
		width: 30px;
		display: block;
		content: " ";
	}

/* ----------------------------------------------------------------------------- */
/* --- PROFILE
/* ----------------------------------------------------------------------------- */

	#profile {
		position: relative;
	}
	
	#profile-bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
	
	#profile-bg img {
		position: absolute;
		top: 0;
		left: 0;
	}
	
	img.too-slim {
		width: 100%;
		max-height: none;
	}
	
	img.too-short {
		height: 100%;
		max-width: none;
	}
	
	#profile .content {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	
	#profile .top-bar a {
		color: inherit;
	}
	
	#profile .top-bar a:hover {
		text-decoration: underline;
	}
	
	.profile-content {
		position: absolute;
		top: 50%;
	}
	
	.profile-bottom-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0px 50px 50px 50px;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- RESUME
/* ----------------------------------------------------------------------------- */
	
	.resume-box {
		position: relative;
		border: 1px solid #eaeaea;
		padding: 35px;
		margin-bottom: 30px;
		z-index: 1;
		transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
	}
	
	.dimmed-effect .resume-box:hover {
		border-color: #fff;
		-webkit-box-shadow: 21px 21px 100px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: 21px 21px 100px 0px rgba(0,0,0,0.1);
		box-shadow: 21px 21px 100px 0px rgba(0,0,0,0.1);
		z-index: 2;
	}
	
	.resume-box > *:last-child {
		margin-bottom: 0px;
	}
	
	.resume-box.disable {
		opacity: .4;
	}

	
	/* Skill bar
	------------------------------------------- */
	
	.skill-name {
		display: block;
		font-weight: 500;
		margin-bottom: 8px;
	}
	
	.skill-bar {
		height: 3px;
		width: 100%;
		background-color: #ddd;
		display: table;
		margin-bottom: 30px;
	}
	
	.skill-rate-on,
	.skill-rate-off {
		display: table-cell;
		border-left: 2px solid #fff;
		border-right: 2px solid #fff;
	}
	
	.sb-blue .skill-rate-on { background-color: #25b5f1; }
	.sb-green .skill-rate-on { background-color: #add71c; }
	.sb-red .skill-rate-on { background-color: #f65757; }
	.sb-yellow .skill-rate-on { background-color: #fbce43; }
	.sb-orange .skill-rate-on { background-color: #f4934c; }
	.sb-violet .skill-rate-on { background-color: #816ae6; }
	.sb-black .skill-rate-on { background-color: #000; }
	
	/* Speciality
	------------------------------------------- */
	
	.speciality {
		padding: 20px 0px;
		border-bottom: 1px solid #eaeaea;
	}
	
	.speciality:last-child {
		border-bottom: none;
	}
	
	.speciality-icon {
		float: left;
	}
	
	.speciality-icon:before {
		font-size: 38px !important;
	}
	
	.speciality-content {
		padding-top: 6px;
		padding-left: 90px;
	}
	
	.speciaity-description {
		color: #757575;
		margin-bottom: 0px;
	}
	
	/* Timeline
	------------------------------------------- */
	
	.timeline {
		position: relative;
		border-left: 1px solid #eaeaea;
		padding: 30px 0px;
	}
	
	.timeline-event {
		position: relative;
		padding-left: 30px;
		padding-bottom: 50px;
	}
	
	.timeline-event:last-child {
		padding-bottom: 0px;
	}
	
	.timeline-event:before {
		position: absolute;
		top: 0;
		left: -9px;
		width: 7px;
		height: 7px;;
		border: 5px solid #fff;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		-webkit-box-sizing: content-box; 
		-moz-box-sizing: content-box; 
		box-sizing: content-box; 
		content: " ";
	}
	
	.te-blue:before { background-color: #25b5f1; }
	.te-green:before { background-color: #add71c; }
	.te-red:before { background-color: #f65757; }
	.te-yellow:before { background-color: #fbce43; }
	.te-orange:before { background-color: #f4934c; }
	.te-violet:before { background-color: #816ae6; }
	.te-black:before { background-color: #000; }
	
	.event-date {
		font-weight: 500;
		font-size: 11px;
		margin-bottom: 20px;
	}
	
	.event-name {
		font-weight: 500;
		font-size: 14px;
	}
	
	.event-description {
		font-size: 11px;
		color: #757575;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- PORTFOLIO
/* ----------------------------------------------------------------------------- */

	.single-project {
		position: relative;
		width: 25%;
		float: left;
		background: #000;
		overflow: hidden;
	}
	
	.single-project a:hover {
		color: inherit;
		text-decoration: none;
	}
	
    .project-mask {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.75);
		opacity: 0;
		visibility: hidden;
		color: #fff;
		transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
	}
	
	.single-project:hover .project-mask {
		opacity: 1;
		visibility: visible;
	}
	
	.project-info {
		position: absolute;
		top: 50%;
		right: -100%;
		width: 100%;
		text-align: right;
		opacity: 0;
		transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
		-moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
		-webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
		-o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
	}
	
	.single-project:hover .project-info {
		right: 0;
		opacity: 1;
	} 
	
	.project-title,
	.project-caption,
	.project-buttons {
		padding: 0 20px;
	}
	
	.project-title {
		font-size: 36px;
		font-weight: 100;
	}
	
	.project-caption {
		font-size: 12px;
		font-weight: 500;
	}
	
	.project-buttons {
		border-top: 1px solid rgba(255,255,255,0.2);
		margin-top: 15px;
		padding-top: 15px;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- BLOG
/* ----------------------------------------------------------------------------- */

	.post {
		margin-bottom: 40px;
        padding-right: 15px;
	}

	.post .post-content {
		transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
	}

	.post-image {
		position: relative;
		z-index: 1;
	}

    .post-image img{
        padding-top: 15px;
    }

	.post-content-holder {
		position: relative;
		margin-top: -70px;
		padding-left: 30px;
		z-index: 2;
	}
	
	.post-content {
		background: #fff;
	}
	
	.post-info {
		padding: 30px;
		line-height: 9px;
		border-bottom: 1px solid #eaeaea;
	}
	
	.post-meta {
		float: right;
		font-size: 10px;
	}
	
	.post-meta span {
		float: left;
		margin-left: 13px;
	}
	
	.post-meta span:first-child {
		margin-left: 0;
	}
	
	.post-meta span i {
		color: #d5d5d5;
		margin-right: 7px;
	}
	
	.dark .post-meta span {
		color: #CCC;
	}
	
	.dark .post-meta span i {
		color: rgba(255,255,255,0.3);
	}
	
	.post-date {
		float: left;
		font-size: 14px;
		font-weight: 500;
	}
	
	.post-text {
		padding: 30px;
	}

	/* Carousel 
	------------------------------------------- */
	
	.post-carousel {
		position:relative;
		overflow: hidden;
    }
		
	.post-carousel-holder {
		position: relative;
		width: 200000em;
		list-style: none;
		margin: 0;
		padding: 0 0 100px 0;
		overflow: hidden;
	}
	
    .post-carousel-item {
		margin-bottom: 60px;
		margin-right: 20px;
    }
	
	.post-carousel-control {
		margin-top: 10px;
		float: right;
	}
	
	.post-carousel-control a:focus {
		color: inherit;
	}
	
	.post-carousel-control a:first-child {
		margin-right: 20px;
		padding-right: 20px;
		border-right: 1px solid #eaeaea;
	}
	
	/* Slider
	------------------------------------------- */
	
	.blog-slider-holder {
		position: relative;
		overflow: hidden;
	}
	
	.blog-slider-top-bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 60px 60px 0px 60px;
		padding-bottom: 30px;
		z-index: 10;
	}
	
	.blog-slide {
		padding-top: 160px !important;
	}
	
	.blog-slide-photo {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		overflow: hidden;
		z-index: 1;
	}
	
	.blog-slide-content {
		position: relative;
		z-index: 2;
	}
	
	.post-date-alt {
		font-size: 13px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	
	/* Comments
	------------------------------------------- */
	
	.comments-list,
	.comments-list ul {
		list-style: none;
		margin: 20px 0 0 0;
		padding: 0;
	}
	
	.comments-list li {
		margin-bottom: 30px;
	}
	
	.comments-list li li {
		margin-left: 45px;
	}
	
	.comments-list li li .comment-content {
		background-color: #fbfbfb;
	}
	
	.comment-avatar {
		float: left;
		width: 90px;
		height: 90px;
		overflow: hidden;
	}
	
	.comment-content {
		padding: 20px;
		border: 1px solid #eaeaea;
		margin-left: 89px;
	}
	
	.comment-meta {
		font-size: 11px;
		text-transform: uppercase;
		font-weight: 500;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #eaeaea;
	}
	
	.comment-reply {
		float: right;
		margin-left: 20px;
	}
	
	.comment-date {
		float: right;
		color: #939393;
		font-size: 10px;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- CONTACT
/* ----------------------------------------------------------------------------- */

	#contact {
		position: relative;
	}

	#google-map {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	
	#google-map img {
		max-width: none;
	}
	
	.contact-content {
		position: relative;
		background: rgba(255,255,255,0.8);
		width: 33%;
		z-index: 2;
		height: 100%;
	}
	
	.black .contact-content {
		background: rgba(0,0,0,0.6);
	}
	
	/* Contact Form
	------------------------------------------- */
	
	#contact-form-holder {
		position: absolute;
		top: 65px;
		z-index: 3;
		color: #fff;
		padding: 30px;
		width: 300px;
		transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
	}
	
	#contact-form-holder.form-hidden { right: -300px; }
    #contact-form-holder.form-visible { right: 0px; }
	
	.contact-form-trigger {
		position: absolute;
		top: 0;
		left: -80px;
		width: 80px;
		height: 80px;
		text-align: center;
		display: block;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-webkit-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
	}
	
	.contact-form-trigger i:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 36px;
		height: 36px;
		margin-left: -18px !important;
		margin-top: -18px;
		font-size: 36px;
		line-height: 36px;
		margin-left: 0;
	}
	
	
/* ----------------------------------------------------------------------------- */
/* --- COMPONENTS
/* ----------------------------------------------------------------------------- */

	/* Buttons
	------------------------------------------- */
	
	.btn {
		color: inherit;
		font-size: 11px;
		font-weight: 500;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		border: none;
		padding: 18px 20px;
		margin-bottom: 3px;
	}

	.btn:active {
		box-shadow: none;
	}

	.btn-sm, .btn-group-sm > .btn {
		padding: 11px 18px
	}
	
	.btn-xs, .btn-group-xs > .btn {
		padding: 7px 14px;
		font-size: 10px;
	}
	
	.btn-lg, .btn-group-lg > .btn {
		padding: 20px 24px;
		font-size: 13px;
	}
	
	.btn-primary, .btn-primary:hover, .btn-primary:focus,
	.btn-black, .btn-black:hover, .btn-black:focus,
	.dark .btn-defalut:hover, .dark .btn-defalut:focus,
    .btn-green, .btn-green:hover,  .btn-green:focus,
	.btn-red, .btn-red:hover,  .btn-red:focus,
	.btn-yellow, .btn-yellow:hover,  .btn-yellow:focus,
	.btn-orange, .btn-orange:hover,  .btn-orange:focus,
	.btn-violet, .btn-violet:hover,  .btn-violet:focus,
	.btn-blue, .btn-blue:hover,  .btn-blue:focus,
	.btn-success, .btn-danger, .btn-warning, .btn-info  { color: #fff; }
	
	.btn-blue{ background-color: #25b5f1; }
	.btn-green { background-color: #add71c; }
	.btn-red { background-color: #f65757; }
	.btn-yellow { background-color: #fbce43; }
	.btn-orange { background-color: #f4934c; }
	.btn-violet { background-color: #816ae6; }
	.btn-black { background-color: #000; }
	.btn-blue:hover, .btn-blue:focus { background-color: #07a2e2; }
	.btn-green:hover, .btn-green:focus { background-color: #92b80e; }
	.btn-red:hover, .btn-red:focus { background-color: #de4131; }
	.btn-yellow:hover, .btn-yellow:focus { background-color: #e6b623; }
	.btn-orange:hover, .btn-orange:focus { background-color: #e67929; }
	.btn-violet:hover, .btn-violet:focus { background-color: #684ce0; }
	.btn-black:hover, .btn-black:focus { background-color: #333; }
	.btn-default { border: 1px solid rgba(0,0,0,0.1); background: none; }
	.btn-default:hover, .btn-default:focus { border: 1px solid rgba(0,0,0,0.2); background: none; }
	.dark .btn-default { border: 1px solid rgba(255,255,255,0.2); background: none; }
	.dark .btn-default:hover, .dark .btn-default:focus { border: 1px solid rgba(255,255,255,0.5); background: none; color: #fff; }
	.btn > i, .btn > i:hover, .btn > i:focus { color: rgba(0,0,0,0.4); }
	.dark .btn-default > i, .dark .btn-default > i:hover, .dark .btn-default > i:focus {
		color: rgba(255,255,255,0.4);
	}
	
	.btn.btn-pull-up { margin-top: -20px; }
	
	/* Labels
	------------------------------------------- */
	
	.label-defalut { border: 1px solid #eaeaea; background: none; color: #333; }
	.dark .label-defalut { border: 1px solid rgba(255,255,255,0.2); background: none; color: #fff; }
	.label-blue{ background-color: #25b5f1; }
	.label-green { background-color: #add71c; }
	.label-red { background-color: #f65757; }
	.label-yellow { background-color: #fbce43; }
	.label-orange { background-color: #f4934c; }
	.label-violet { background-color: #816ae6; }
	.label-black { background-color: #000; }
	
	/* Social Icons
	------------------------------------------- */
	
	ul.social-icons {
		display: inline-block;
		padding: 0;
		margin: 0;
	}
	
	ul.social-icons.si-pull-up {
		margin-top: -25px;
	}
	
	ul.social-icons li {
		list-style-type: none;
		margin: 0 4px 0 0;
		padding: 0px;
		border: 1px solid rgba(0,0,0,0.1);
		font-size: 11px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		display: inline-block;
	}
	
	.black ul.social-icons li { 
		border: 1px solid rgba(255,255,255,0.2);
	}
	
	ul.social-icons li a {
		display: block;
		width: 36px;
		height: 52px;
		text-align: center;
	}
	
	ul.social-icons li a i {
		margin-top: 17px;
		font-size: 15px;
		line-height: 15px;
		transition: all 0.6s ease-in-out;
		-moz-transition: all 0.6s ease-in-out;
		-webkit-transition: all 0.6s ease-in-out;
		-o-transition: all 0.6s ease-in-out;
		opacity: .4;
	}
	
	ul.social-icons li a:hover i {
		margin-top: 17px;
		font-size: 15px;
		line-height: 15px;
		transition: all 0.6s ease-in-out;
		-moz-transition: all 0.6s ease-in-out;
		-webkit-transition: all 0.6s ease-in-out;
		-o-transition: all 0.6s ease-in-out;
		opacity: .4;
	}
	
	ul.social-icons li a:hover i {
		transform:rotateY(360deg);
		-moz-transform:rotateY(360deg);
		-webkit-transform:rotateY(360deg);
		-o-transform:rotateY(360deg);
		opacity: 1;
	}

	/* Navs
	------------------------------------------- */
	
	.nav-pills,
	.nav-tabs {
		margin-bottom: 20px;
	}
	
	.nav-tabs li a,
	.nav-pills li a {
		font-size: 11px;
		font-weight: 500;
		padding: 16px 20px;
	}
	
	.nav-tabs li a:hover, .nav-tabs li a:focus,
	.nav-pills li a:hover, .nav-pills li a:focus {
		color: inherit;
		background: #f2f2f2;
	}
	
	.nav-tabs li a {
		-webkit-border-top-left-radius: 2px;
		-webkit-border-top-right-radius: 2px;
		-moz-border-radius-topleft: 2px;
		-moz-border-radius-topright: 2px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}
	
	.nav-pills li a {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	/* Accordion
	------------------------------------------- */
	
	.panel-group .panel {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	.panel-default > .panel-heading {
		border: #eaeaea;
		background: #fff;
		padding: 19px 20px;
	}
	
	.panel-default > .panel-heading:hover {
		background: #f2f2f2;
	}
	
	.panel-title {
		font-size: 12px;
	}
	
	.panel-title a:hover,
	.panel-title a:focus {
		color: inherit;
	}
	
	/* Progress Bars
	------------------------------------------- */
	
	.progress {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow:  none;
		box-shadow: none;
		background-color: #f2f2f2;
	}
	
	.progress-bar {
		-webkit-box-shadow: none;
		-moz-box-shadow:  none;
		box-shadow: none;
	}
	
	.progress-bar-blue{ background-color: #25b5f1; }
	.progress-bar-green { background-color: #add71c; }
	.progress-bar-red { background-color: #f65757; }
	.progress-bar-yellow { background-color: #fbce43; }
	.progress-bar-orange { background-color: #f4934c; }
	.progress-bar-violet { background-color: #816ae6; }
	.progress-bar-black { background-color: #000; }
	
	/* Other
	------------------------------------------- */
	
	.list-inline li {
		margin-right: 15px;
	}
	
	.list-unstyled-icons .inline-icon {
		float: left;
		margin-left: -35px;
	}
	
	.list-unstyled-icons li {
		padding-left: 35px;
		margin-bottom: 15px;
	}

	.list-unstyled-icons li:last-child {
		margin-bottom: 0px;
	}
	
	.list-group-item:first-child,
	.list-group-item:last-child {
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
	}
	
	.popover {
		-webkit-box-shadow: 5px 5px 50px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 5px 5px 50px 0px rgba(0,0,0,0.15);
		box-shadow: 5px 5px 50px 0px rgba(0,0,0,0.15);
		border: none;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	.popover.bottom > .arrow { border-bottom-color: #fff; }
	.popover.top > .arrow { border-top-color: #fff; }
	.popover.left > .arrow { border-left-color: #fff; }
	.popover.right > .arrow { border-right-color: #fff ; }
	
	.tooltip-inner {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px
	}
	
	.well {
		-webkit-box-shadow: none;
		-moz-box-shadow:  none;
		box-shadow: none;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	.alert {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	blockquote {
		font-weight: 200;
	}
	
	blockquote footer {
		font-size: 11px;
		font-weight: 500;
	}
	
	.form-control {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	.dark .form-control {
		border: none;
	}
	
	.form-control:focus {
		border-color: #ccc;
		outline: 0px none;
		-webkit-box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.15);
		box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.15);
	}
	
	.dark .form-control:focus {
		border-color: #ccc;
		outline: 0px none;
		-webkit-box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.3);
		-moz-box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.3);
		box-shadow: 3px 3px 30px 0px rgba(0,0,0,0.3);
	}
	
	.modal-content {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	
	.carousel-control:hover,
	.carousel-control:hover *,
	.carousel-control:focus,
	.carousel-control:focus * {
		color: #fff;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- MEDIA QUERIES
/* ----------------------------------------------------------------------------- */

    @media (min-width: 1500px) {

        #page-content {
            padding-left: 200px;
        }

        .container {
            width: 920px;
        }

        /* Header */

        header {
            width: 200px;
        }

        .share-container{
            margin-top: 65px;
        }

        .headline-container{
            padding: 59px 0 17px 0;
        }

        .headline-container-short{
            padding: 12px 0 11px 0;
            margin-bottom: 10px;
        }

        .main-content{
            padding: 0 0 0 200px;
        }

        .single-image-item{
            width: auto;
            height: 400px;
            max-height: 400px;
        }

        .single-image {
            margin-right:auto;
            margin-left:auto;
            width: auto;
            height: 400px;
            max-height: 400px;
        }

        .page-title{
            padding: 0 0 0 260px;
        }

        .me-logo {
            height:200px;
        }

        .nav-logo{
            text-align: right;
            padding: 15px 0 10px 25px;
        }

        /* Portfolio */

        .single-project {
            width: 33.33333%;
        }

        /* Contact */

        .contact-content {
            width: 45%;
        }

    }

    @media (min-width: 1200px) {

    }


	@media (max-width: 1500px) {
		
		#page-content {
			padding-left: 200px;
		}
		
		.container {
			width: 920px;
		}
		
		/* Header */
		
		header {
			width: 200px;
		}

        .headline-container{
            padding: 56px 0 16px 0;
        }

        .headline-container-short{
            padding: 10px 0 9px 0;
            margin-bottom: 10px;
        }

        .main-content{
            padding: 0 0 0 200px;
        }

        .single-image-item{
            width: auto;
            height: 3500px;
            max-height: 350px;
        }

        .single-image {
            margin-right:auto;
            margin-left:auto;
            width: auto;
            height: 350px;
            max-height: 3500px;
        }

        .page-title{
            padding: 0 0 0 200px;
        }
		
		.me-logo {
			height:200px;
		}

        .nav-logo{
            text-align: right;
            padding: 10px 0 8px 20px;
        }

		/* Portfolio */
		
		.single-project {
			width: 33.33333%;
		}
		
		/* Contact */
		
		.contact-content {
			width: 45%;
		}
		
	}

	@media (min-width: 1200px) {
		
	}
	
	@media (max-width: 1199px) {
		
		#page-content {
			padding-left: 80px;
		}
		
		.container {
			width: 830px;
			margin: -60px 40px 0px 40px;
		}
		
		/* Setup */
		
		h2 { font-size: 53px; }
		h3 { font-size: 42px; }
		
		/* Basic */
		
		.padded { padding: 40px; }
		.top-bar { padding-bottom: 55px; }
		.bottom-bar { padding-top: 40px; }
		
		/* Header */
		
		header {
			width: 80px;
		}

        .share-container{
            margin-top: 0;
        }

        .headline-container{
            padding: 20px 0;
        }

        .headline-container-short{
            padding: 0 0 7px 0;
            margin-bottom: 10px;
        }

        .contact {
            padding-left: 80px;
        }

        .main-content{
            padding: 0 0 0 80px;
        }

        .single-image-item{
            width: auto;
            height: 300px;
            max-height: 300px;
        }

        .single-image {
            margin-right:auto;
            margin-left:auto;
            width: auto;
            height: 300px;
            max-height: 300px;
        }

        .page-title{
            padding: 0 0 0 80px;
        }

        .footer-bar{
            text-align: left;
        }

        .nav-logo{
            text-align: right;
            padding: 0;
        }

		.me-logo {
			display: none;
		}
		
		.me-nav {
			text-align: center;
		}
		
		.me-nav > li > a > span {
			display: none;
		}
		
		.me-nav > li > a > i {
			margin: 0;
		}
		
		.copyright {
			display: none;
		}
		
		/* Profil */
		
		.profile-bottom-bar {
			margin: 0 40px 35px 40px;
		}
		
		/* Blog */
		
		.blog-slider-top-bar { 
			margin: 40px 40px 0 40px;
			padding-bottom: 20px;
		}
		
		/* Contact */
		
		#contact-form {
			top: 55px;
		}
	
	}
	
	@media (min-width: 992px) and (max-width: 1199px) {
		
	}
	
	@media (max-width: 991px) {
		
		.container {
			width: 630px;
			margin: -60px 30px 0 30px;
		}

        .post {
            padding-right: 0px;
        }

		/* Setup */
		
		h1 { font-size: 60px; }
		h2 { font-size: 50px; }
		h3 { font-size: 40px; }
		
		/* Basic */

		.padded { padding: 30px; }
		
		.top-bar { padding-bottom: 45px; margin-bottom: 40px; }
		.bottom-bar { padding-top: 40px; margin-top: 40px; }

        .headline-container{
            padding: 20px 0;
        }

        .headline-container-short{
            padding: 0 0 7px 0;
            margin-bottom: 10px;
        }

        .footer-bar{
            margin-left: -20px;
        }

        .single-image-item{
            width: auto;
            height: 220px;
            max-height: 220px;
        }

        .single-image {
            margin-right:auto;
            margin-left:auto;
            width: auto;
            height: 220px;
            max-height: 220px;
        }

		/* Profile */
		
		.profile-bottom-bar {
			margin: 0 30px 40px 30px;
		}
		
		/* Portfolio */
		
		.single-project {
			width: 50%;
		}
		
		/* Blog */
		
		.blog-slider-top-bar { 
			margin: 40px 30px 0px 30px;
			padding-bottom: 20px;
		}
		
		/* Contact */
		
		.contact-content {
			width: 50%;
		}
	
	}

	@media (min-width: 768px) and (max-width: 991px) {
		  
	}
	
	@media (max-width: 767px) {
		
		.container {
			padding: 30px;
			width: 85%;
		}

        .container-fluid{
            padding-left: 15px;
        }
		
		/* Setup */
		
		h1 { font-size: 49px; }
		h2 { font-size: 40px; }
		h3 { font-size: 32px; }
		h5 { font-size: 14px; }
		
		/* Basic */
		
		.padded-short, .padded { padding: 30px; }
		.padded-vertical{
            padding-bottom: 0;
        }
		/* Header */
		
		header {
			width: 45px;
		}

        .headline-container{
            padding: 15px 0;
        }

        .article-index-container{
           padding-right: 0;
        }

        .contact {
            padding-left: 45px;
        }

        .main-content{
            padding: 0 0 0 45px;
        }

        .page-title{
            padding: 0 0 0 45px;
        }
		
		#page-content {
			padding-left: 45px;
		}
		
		.me-nav i {
			margin-left: -1px !important;
		}
		
		/* Profile */
		
		#profile .top-bar {
			border: none;
		}
		
		/* Resume */
		
		.resume-box {
			padding: 20px;
		}
		
		.speciality .speciality-content {
			padding-left: 60px;
		}
		
		.speciality .speciality-icon:before {
			margin-left: 10px;
			font-size: 32px !important;
		}
		
		/* Portfolio */
		
		.single-project {
			width: 100%;
		}
		
		/* Blog */
		
		.post .post-content-holder {
			margin-top: -50px;
			padding-left: 20px;
		}
		
		.post .post-info,
		.post .post-text {
			padding: 20px;
		}
		
		.post .post-date {
			font-size: 12px;
		}
		
		.post .post-meta {
			display: none;
		}
		
		.comment-avatar {
			display: none;
		}
		
		.comment-content {
			margin-left: 0;
		}
		
		.comments-list li li {
			margin-left: 0;
		}
		
		.post-carousel-control {
			display: none;
		}

        .single-head-padding{
            padding-bottom: 0;
        }

		/* Contact */
		
		.contact-content {
			width: 100%;
		}
		
		.contact-form-trigger {
			left: -60px;
			width: 60px;
			height: 60px;
		}
		
		.contact-form-trigger i:before {
			width: 24px;
			height: 24px;
			margin-left: -12px !important;
			margin-top: -12px;
			font-size: 24px;
			line-height: 24px;
		}
		
		#contact-form-holder {
			width: 200px;
			padding: 20px;
		}
		
		#contact-form-holder.form-hidden { right: -200px; }
	
	}
	
/* ----------------------------------------------------------------------------- */
/* --- PAGE LOADER
/* ----------------------------------------------------------------------------- */
	
	#page-loader {
		position:fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9100 !important;
		width: 100%;
		height: 100%;
		background-color: #fff;
	}
	
	.pace {
		-webkit-pointer-events: none;
		pointer-events: none;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		
		-webkit-perspective: 12rem;
		-moz-perspective: 12rem;
		-ms-perspective: 12rem;
		-o-perspective: 12rem;
		perspective: 12rem;
		
		z-index: 2000;
		position: fixed;
		height: 6rem;
		width: 6rem;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	.pace.pace-inactive .pace-progress {
		display: none;
	}
	
	.pace .pace-progress {
		position: fixed;
		z-index: 2000;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 65px;
		width: 65px !important;
		line-height: 20px;
		font-size: 20px;
		border-radius: 0px;
		color: #fff;
		font-weight: 100;
		text-align: center;
	}
	
	.pace .pace-progress:after {
		margin-top: 22px;
		content: attr(data-progress-text);
		display: block;
	}
	
/* ----------------------------------------------------------------------------- */
/* --- BACKGROUNDS
/* ----------------------------------------------------------------------------- */

	
	